.pay-panel-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, .2);
    backdrop-filter: blur(10px);
    opacity: 0;
    transform: translateZ(0);
    transition: opacity .3s;

    .pay-panel {
        width: 1080px;
        height: 660px;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%);
        background: #f9f9f9;

        display: flex;
        flex-direction: column;

        .pay-panel-close {
            width: 36px;
            height: 36px;
            position: absolute;
            z-index: 10;
            right: 18px;
            top: 18px;
            background: url(../../assets/images/close_icon.png) no-repeat center;
            background-size: contain;
            transform: rotate(0);
            transition: transform .3s;
            cursor: pointer;
        }

        .pay-panel-close:hover {
            transform: rotate(180deg);
        }

        .pay-panel-header {
            height: 48px;
            width: 100%;
            margin-top: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .pay-panel-user {
                margin-left: 36px;
                display: flex;
                align-items: center;

                .user-avatar {
                    width: 48px;
                    height: 48px;
                    position: relative;

                    img {
                        width: 48px;
                        height: 48px;
                        border-radius: 100%;
                    }

                    span {
                        width: 28px;
                        height: 12px;
                        position: absolute;
                        left: 10px;
                        bottom: 0;
                    }

                    span.vip-1 {
                        background: url(../../assets/images/vip.png) no-repeat center;
                        background-size: contain;
                    }

                    span.vip-2 {
                        background: url(../../assets/images/svip.png) no-repeat center;
                        background-size: contain;
                    }
                }

                .user-info {
                    margin-left: 12px;
                    // align-items: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: start;

                    span {
                        color: #666;

                        i {
                            margin-left: 6px;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }

                .user-info>:first-child {
                    color: #222;
                    margin-bottom: 5px;
                }
            }
        }

        .points-info {
            margin-right: 78px;

            .points-total {
                height: 30px;
                line-height: 30px;
                color: #555;
                font-size: 18px;
                display: flex;
                justify-content: end;
                align-items: center;

                span {
                    padding-left: 24px;
                    background: url(../../assets/images/point_color.png) no-repeat left center;
                    background-size: contain;
                }

                i {
                    font-size: 14px;
                    font-style: normal;
                    color: #999;
                }
            }

            .points-bar {
                width: 200px;
                height: 18px;
                margin-top: 4px;
                background-color: #fff;
                border: 1px solid #f1f1f1;
                border-radius: 9px;
                position: relative;

                i {
                    height: 18px;
                    border-radius: 9px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: linear-gradient(90deg, #FF66BF, #6175FF);
                }
            }
        }

        .pay-panel-tab {
            width: 100%;
            height: 48px;
            margin-top: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: white;
            background-image: url(../../assets/images/pay_tab_vip.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            span {
                height: 48px;
                flex: 1;
                cursor: pointer;
            }
        }

        .pay-container {
            width: 100%;
            flex: 1;
            border-radius: 0 0 6px 6px;
            background: white;

            .pay-option-label {
                width: 100%;
                height: 24px;
                position: absolute;
                z-index: 2;
                display: flex;
                align-items: start;
                justify-content: center;

                img {
                    height: 24px;
                }
            }

            .pay-container-section {
                display: none;
                justify-content: space-between;
                margin-top: 25px;

                .benefits {
                    width: 270px;
                    height: 431px;
                    margin-left: 36px;
                    border: 2px solid rgba(17, 17, 17, 0.05);
                    box-sizing: border-box;
                    padding-top: 90px;
                    background: url(../../assets/images/pay_benefit_bg.png) no-repeat center top;
                    background-size: contain;

                    div {
                        font-size: 24px;
                        color: #666;
                        padding-left: 20px
                    }

                    ul {
                        margin: 20px 0 0 20px;
                        padding: 0;
                        list-style: none;

                        li {
                            height: 20px;
                            line-height: 20px;
                            color: #666;
                            margin-bottom: 8px;
                            padding-left: 24px;
                            background: url(../../assets/images/pay_benefit_icon.png) no-repeat left center;
                            background-size: contain;
                        }
                    }
                }

                .pay-options {
                    margin: 0 36px 0 20px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: cneter;

                        li {
                            box-sizing: border-box;
                            width: 164px;
                            height: 212px;
                            justify-content: space-between;
                            border-radius: 6px;
                            background: #fff;
                            transform: translateZ(0);
                            transition: background .3s;

                            div {
                                height: 212px;
                                box-sizing: border-box;
                                border-radius: 6px;
                                display: flex;
                                flex-direction: column;
                                text-align: center;
                                border: 2px solid #f5f5f5;
                                background: #fff;
                                transform: translateZ(0);
                                transition: all .3s;

                                .pay-option-title {
                                    margin-top: 42px;
                                    font-size: 24px;
                                    color: #777;
                                }

                                .original-price {
                                    color: #aaa;
                                    margin-top: 11px;
                                    text-decoration: line-through;
                                }

                                .current-price {
                                    font-size: 24px;
                                    color: #444;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    i {
                                        font-size: 36px;
                                    }
                                }

                                .unit-price {
                                    color: #aaa;
                                    margin-top: 6px;
                                }

                            }
                        }

                        li.curr-option {
                            background: linear-gradient(90deg, #FF66BF, #6175FF);

                            div {
                                border-color: #fff;
                            }
                        }
                    }

                    .pay-scan-qrcode {
                        margin-top: 20px;
                        display: flex;
                        flex: 1;
                        align-items: center;
                        border: 2px solid #f5f5f5;

                        .qrcode {
                            width: 160px;
                            height: 160px;
                            padding: 10px;
                            margin-left: 20px;
                            box-sizing: border-box;
                            border-radius: 6px;
                            border: 1px solid #f5f5f5;

                            img {
                                width: 140px;
                                height: 140px;
                                vertical-align: top;
                            }
                        }

                        .qrcode-info {
                            margin-left: 24px;

                            p {
                                margin: 0;
                                padding: 0;
                                display: flex;
                                align-items: center;
                            }

                            .qrcode-text {
                                color: #444;

                                i {
                                    font-size: 24px;
                                }

                                .alipay,
                                .wxpay {
                                    height: 18px;
                                    padding-left: 18px;
                                    background: url(../../assets/images/alipay_icon.png) no-repeat left center;
                                    background-size: contain;
                                }

                                .wxpay {
                                    background: url(../../assets/images/wxpay_icon.png) no-repeat left center;
                                    background-size: contain;
                                }
                            }

                            .agreement-info {
                                font-size: 12px;
                                color: #aaa
                            }
                        }

                        .qrcode-info>:nth-child(2) {
                            margin-top: 12px;
                        }

                        .qrcode-info>:nth-child(3) {
                            margin-top: 10px;
                        }
                    }
                }
            }

            .pay-container-vip {
                display: flex;
            }
        }
    }

    .pay-panel-svip {

        .pay-panel-tab {
            background-image: url(../../assets/images/pay_tab_svip.png);
            background-size: contain;
        }

        .pay-container {

            .pay-container-vip {
                display: none;
            }

            .pay-container-svip {
                display: flex;
            }
        }
    }

    .pay-history {
        width: 1080px;
        height: 660px;
        position: fixed;
        z-index: 101;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 18px;
        background: #f9f9f9;
        opacity:0;
        transition: opacity .3s;

        .ph-header {
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h6 {
                display: flex;
                align-items: center;
                font-weight: normal;

                i {
                    width: 36px;
                    height: 36px;
                    background: url(../../assets/images/arrow_left.png) no-repeat center;
                    background-size: contain;
                    cursor:pointer;
                }

                span {
                    flex: 1;
                    font-size: 24px;
                    color: #555;
                    margin-left: 15px;
                }
            }

            div {
                width: 36px;
                height: 36px;
                background: url(../../assets/images/close_icon.png) no-repeat center;
                background-size: contain;
                cursor: pointer;
                transform: rotate(0);
                transition: transform .3s;
                cursor: pointer;
            }
    
            div:hover {
                transform: rotate(180deg);
            }
        }

        .ph-thead {
            height: 26px;
            margin-top: 10px;
            display: flex;

            span {
                color: #a6a6a6;
                text-align: center;
            }

            >:nth-child(1) {
                width: 160px;
            }

            >:nth-child(2) {
                width: 200px;
            }

            >:nth-child(3) {
                width: 200px;
            }

            >:nth-child(4) {
                width: 200px
            }

            >:nth-child(5) {
                flex: 1
            }
        }

        .ph-tbody {
            flex:1;
            overflow-y:auto;
            ul {
                padding: 0;
                padding: 0;
                list-style: none;

                li {
                    height: 60px;
                    margin-bottom:6px;
                    border-radius: 9px;
                    display: flex;
                    align-items: center;
                    background: white;

                    span {
                        color: #666;
                        text-align: center;
                    }

                    >:nth-child(1) {
                        width: 160px;
                        font-size: 18px;
                        background-image: linear-gradient( 90deg, #6175ff 35%, #ff66bf 60%);
                        -webkit-background-clip: text;
                        color: transparent;
                    }

                    >:nth-child(2) {
                        width: 200px;
                    }

                    >:nth-child(3) {
                        width: 200px;
                    }

                    >:nth-child(4) {
                        width: 200px
                    }

                    >:nth-child(5) {
                        flex: 1
                    }
                }
            }
        }

    }
    .pay-history-v{
        opacity:1;
    }
}

.pay-panel-bg::before {
    content: "";
    width: 1080px;
    height: 660px;
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    border-radius: 6px;
    transform: translate(-50%, -50%);
    background: linear-gradient(45deg, rgba(255, 102, 191, .6), rgba(97, 117, 255, .6));
    border-radius: 6px;
    filter: blur(6px);
    z-index: -1;
}

.pay-panel-bg-v {
    opacity: 1;
}