
.crop-image-panel{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom:60px;
    position:absolute;
    z-index: 300;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    background:rgba(0,0,0,.4);
    opacity:0;
    pointer-events: none;
    transition: opacity .3s;
    backdrop-filter:blur(10px);
}
.crop-image-panel-v{
    opacity:1;
    pointer-events: all;
}