.main-wrapper {
    width: 100%;
    height: 100%;
    min-width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // justify-content: space-between;

    .create-con {
        width: 35.625vw;
        min-width: 400px;
        min-height: 560px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        background: #fff;

        position: relative;
        ;

        .create-poster {
            width: 240px;
            height: 53vh;
            top: 0;
            background:url(../../assets/images/logo_text.png) no-repeat center;
            background-size: contain;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

        }

        .create-panel {
            flex: 1;
            width: 86%;
            top: 35vh;
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .music-list-con {
        box-sizing: border-box;
        width: 64vw;
        min-width: 500px;
        height: 100vh;
        min-height: 720px;
        position: relative;
        z-index: 1;

        .header {
            border-bottom: 1px solid #e9e9e9;
        }

        .footer {
            position: absolute;
            z-index: 6;
            left: 0;
            right: 0;
            bottom: 0;
            box-sizing: border-box;
            padding:0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top:1px solid #e9e9e9;
        }

        .music-list-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            background: linear-gradient(90deg, #40a7004e, #fff);


        }

        .music-list-bg::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: linear-gradient(90deg, rgb(249, 249, 249, 0), #f9f9f9 18px);
        }
    }
}