.work-container {
    // margin-top: 24px;

    .work-scroll-title {
        // margin-top: 24px;
        padding: 0 20px;

        .my-create-title {
            display: flex;
            width: 100%;
            align-items: center;
            height: 36px;

            b {
                width: 18px;
                height: 18px;
                background: url(../../../assets/images/my_create_title_icon.png) no-repeat center;
                background-size: contain;
            }

            span {
                margin-left: 6px;
                font-size: 16px;
                color: #3f3f3f;
            }

            i {
                height: 18px;
                width: 18px;
                margin-left: 6px;
                line-height: 18px;
                text-align: center;
                margin-left: 6px;
                font-style: normal;
                background: #3f3f3f;
                font-size: 12px;
                color: white;
                border-radius: 5px;
                transform: scale(.8) translate(0px, 0px) skew(-9deg, 0deg)
            }
        }
    }

    .recommend-title {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;

        b {
            width: 18px;
            height: 18px;
            background: url(../../../assets/images/sys_music_title_icon.png) no-repeat center;
            background-size: contain;
        }

        span {
            color: #333;
            margin-left: 6px;
        }
    }

    .work-container-scroll {
        padding: 0 20px;
        overflow-y: auto;

        .my-create {

            .tasks {
                .task {
                    width: 100%;
                    height: 60px;
                    overflow: hidden;
                    margin-top: 6px;
                    border-radius: 6px;
                    background-color: white;
                    position: relative;

                    .pg-bar {
                        display: block;
                        width: 0;
                        height: 60px;
                        box-sizing: border-box;
                        transform: translateZ(0);
                        background: linear-gradient(270deg, rgba(98, 117, 255, 0.10) 8.69%, rgba(98, 117, 255, 0.00) 91.69%);
                        transition: width .3s;
                    }

                    .pg-percent {
                        position: absolute;
                        min-width: 250px;
                        height: 60px;
                        line-height: 60px;
                        padding-right: 24px;
                        color: #aaa;
                        font-size: 18px;
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        transform: translateZ(0);
                        transition: width .3s;

                        b {
                            flex-shrink: 0;
                            width: 36px;
                            height: 36px;
                            margin-left: 18px;
                            background: url(../../../assets/images/creating_img.png) no-repeat center;
                            background-size: contain;
                        }

                        i {
                            flex: 1;
                            flex-shrink: 0;
                            font-style: normal;
                            min-width: 130px;
                            text-indent: 18px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        em {
                            flex-shrink: 0;
                            margin-left: 12px;
                            padding-right: 24px;
                        }
                    }

                }
            }

            .music {
                margin-top: 6px;
            }
        }

        .button-base {
            height: 30px;
            line-height: 30px;
            padding: 0 12px;
            border-radius: 6px;
            border: 1px solid rgba(17, 17, 17, 0.05);
            background: #FFF;
            transform: translateZ(0);
            transition: all .2s;
            user-select: none;

        }

        .button-base:hover {
            border: 1px solid rgba(17, 17, 17, 0.1);
        }

        .button-play {
            width: 60px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background: 'linear-gradient(90deg, #6175FF, #FF66BF)';
            user-select: none;
        }

        .button-base {
            height: 30px;
            line-height: 30px;
            margin-right: 12px;
            padding: 0 14px;
            color: #707070;
            border-radius: 6px;
            border: 1px solid rgba(17, 17, 17, 0.05);
            background: #fff;
            cursor: pointer;

        }

        .button-play {
            width: 60px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(90deg, #6175ff, #ff66bf);
            cursor: pointer;

            span {
                width: 10px;
                height: 12px;
                background: url(../../../assets/images/list_music_start.png) no-repeat center;
                background-size: contain;
            }
        }

        .music {
            box-sizing: border-box;
            height: 89px;
            margin-top: 6px;
            border-radius: 6px;
            transform: translateZ(0);
            transition: all .3s;
            position: relative;
            background: #fff;

            .foreground {
                position: absolute;
                z-index: 0;
                top: 2px;
                right: 2px;
                bottom: 2px;
                left: 2px;
                border-radius: 4px;
                background-color: #fff;
                display: flex;
                align-items: center;

                .music-poster {
                    flex-shrink: 0;
                    width: 65px;
                    height: 65px;
                    margin-left: 16px;
                    border-radius: 6px;
                    background-color: #f3f3f3;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .music-info {
                    margin-left: 12px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;

                    .title {
                        width: 80%;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        color: #707070;

                        span {
                            margin-right: 6px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .description {
                        width: 90%;
                        font-size: 14px;
                        margin-top: 6px;
                        overflow: hidden;
                        color: #b8b8b8;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }

            .handler {
                height: 30px;
                position: absolute;
                right: 18px;
                bottom: 28px;
                display: flex;
                align-items: center;
                justify-content: end;
                opacity: 0;
                pointer-events: none;
                transform: translateZ(0);
                transition: opacity .3s;
            }
        }

        .music-current {
            .button-play {
                span {
                    background: url(../../../assets/images/list_music_pause.png) no-repeat center;
                    background-size: contain;
                }
            }

            .title {
                span:nth-child(2) {
                    background-image: linear-gradient(270deg, #FF66BF 11.26%, #6175FF 88.74%);
                    color: #555;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .music-pause-start {
            .button-play {
                span {
                    background: url(../../../assets/images/list_music_start.png) no-repeat center;
                    background-size: contain;
                }
            }
        }

        .music-v {
            background: linear-gradient(90deg, rgba(97, 117, 255, 0.3), rgba(255, 102, 191, .3));

            .handler {
                pointer-events: all;
                opacity: 1;
            }

            .foreground .music-info .description {
                width: 60%;
            }
        }

        // .music:hover {
        //     border-radius: 6px;
        //     border: 2px solid #FF67C0;
        //     background: #fff;
        //     border-image: linear-gradient(90deg, rgba(97, 117, 255, .3), rgba(255, 102, 191, .3)) 2 2 2 2;
        // }

        .recommend-panel {
            margin: 0;
            position: relative;
            z-index: 5;
            padding-top: 33px;
            .music-list{
                padding-bottom:42px;
            }
        }

        .loading {
            margin-top:36px;
            padding-bottom:20px;
            display: flex;
            align-items: center;
            justify-content: center;
            // span {
            //     font-size: 12px;
            //     color: #bbb;
            // }
            i{
                width:22px;
                height:18px;
                background:url(../../../assets/images/loading.gif) no-repeat center;
                background-size: contain;
            }

        }
    }

    @keyframes rotate360 {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}