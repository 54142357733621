
.header {
    box-sizing: border-box;
    padding:18px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 56px;
    .logo{
        min-width:200px;
        height:36px;
        flex: 1;
        display: flex;
        background:url(../../assets/images/logo.png) no-repeat;
        background-size: contain;
        a,a:link, a:visited{
            width:200px;
            height:36px;
        }
    }
    .back_btn{
        width: 72px;
        height: 36px;
        cursor: pointer;
        
        img{
            width: 100%;
        }
    }
}