.audio-panel {
    min-width: 700px;
    height: 84px;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    transform: translateY(84px);
    transition: transform .3s;
    border-top: 2px solid #d9d9d9;

    .time-slider {
        width: 100%;
        height: 6px;
        position: relative;
        z-index: 2;
        background: rgba(97, 97, 97, .3);

        .time-slider-fill {
            pointer-events: none;
            height: 6px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0 2px 2px 0;
            background: linear-gradient(90deg, #40A700, #40A700);
        }

        .time-handle {
            height: 18px;
            width: 18px;
            box-sizing: border-box;
            border: 2px solid #fff;
            border-radius: 100%;
            position: absolute;
            left: -6px;
            top: -6px;
            background-color: #40A700;
            transform: translateZ(0);
            // box-shadow: 0 0 3px #bbb;
            cursor: pointer;
        }
    }

    .audio-body {
        width: 100%;
        height: 72px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f9f9f9;

        .music-info {
            flex-basis: 0;
            flex-grow: 1;
            align-items: center;
            // position: absolute;
            // left: 18px;
            // top:21px;
            margin: 0 0 0 18px;
            display: flex;

            overflow: hidden;
            text-overflow: ellipsis;

            .music-poster {
                flex-shrink: 0;
                width: 36px;
                height: 36px;
                border-radius: 6px;
                background-color: #e8e8e8;
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
            }

            .music-ext {
                margin-left: 12px;
                overflow: hidden;
                text-overflow: ellipsis;

                .music-title {
                    font-size: 18px;
                    color: #111;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                }

                .music-time {
                    font-size: 16px;
                    color: #b3b3b3;
                }
            }
        }

        .ap-buttons {
            flex-basis: 0;
            // flex-grow: 1;
            width: 216px;
            min-width: 216px;
            margin: 0 18px;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                cursor: pointer;
            }

            .ap-prev {
                width: 48px;
                height: 48px;
                background: url(../../assets/images/audio_prev.png) no-repeat center;
                background-size: contain;
            }

            .ap-next {
                width: 48px;
                height: 48px;
                background: url(../../assets/images/audio_next.png) no-repeat center;
                background-size: contain;
            }

            .ap-start {
                width: 48px;
                height: 48px;
                background: url(../../assets/images/audio_play.png) no-repeat center;
                background-size: contain;
                transform: translateZ(0);
                transition: background .1s;
            }

            .ap-pause {
                background: url(../../assets/images/audio_pause.png) no-repeat center;
                background-size: contain;
            }
        }


        .ap-layout {
            flex-basis: 0;
            flex-grow: 1;
            display: flex;
            justify-content: right;

            .ap-additional {
                // position: absolute;
                // right:18px;
                // top:21px;

                width: 228px;
                margin: 0 18px 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .likeup-btn {
                    width: 36px;
                    height: 36px;
                    background: url(../../assets/images/likeup.png) no-repeat center;
                    background-size: contain;
                    transition: background .1;
                    cursor: pointer;
                }

                .likeup-btn-ed {
                    background: url(../../assets/images/likeup_ed.png) no-repeat center;
                    background-size: contain;
                }

                .likeup-btn-hide {
                    visibility: hidden;
                    pointer-events: none;
                }

                .share-btn {
                    width: 36px;
                    height: 36px;
                    background: url(../../assets/images/audio_share_icon.png) no-repeat center;
                    background-size: contain;
                    cursor: pointer;
                }

                .ap-volume {
                    width: 112px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    transform: translateZ(0);
                    transition: all .3s;
                    border: 1px solid #eee;
                    border-radius: 17px;
                    background: #fff;

                    .ap-volume-icon {
                        width: 34px;
                        height: 34px;
                        border-radius: 12px;
                        cursor: pointer;
                        background: url(../../assets/images/volume_icon_opacity.png) no-repeat left center;
                        background-size: 34px 34px;
                    }

                    .volume-slider {
                        width: 66px;
                        height: 4px;
                        margin-left: 0px;
                        border-radius: 2px;
                        opacity: 1;
                        position: relative;
                        background: #e7e7e7;
                        transform: translateZ(0);
                    }

                    .volume-slider-fill {
                        pointer-events: none;
                        width: 0;
                        height: 4px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 2px;
                        transform: translateZ(0);
                        // transition: width .2s;
                        background: #40A700;

                    }

                    .volume-handle {
                        width: 8px;
                        height: 8px;
                        overflow: hidden;
                        position: absolute;
                        z-index: 11;
                        left: -2px;
                        top: -2px;
                        border-radius: 50%;
                        background: #40A700;
                        opacity: 1;
                        transform: translateZ(0);
                        // transition: all .2s;
                        cursor: default;
                    }

                    .volume-handle:hover {
                        box-shadow: 0 0 5px #40A700;
                    }

                    // .volume-handle:active {
                    //     width: 10px;
                    //     height: 10px;
                    //     top: -3px;
                    //     box-shadow: 0 0 5px #FF66BF;
                    // }
                }

                .ap-volume-mute {
                    .ap-volume-icon {
                        background: url(../../assets/images/volume_icon_opacity_mute.png) no-repeat left center;
                        background-size: 34px 34px;
                    }
                }

                // .ap-volume-1 {
                //     .ap-volume-icon {
                //         width: 13px;
                //         border-radius: 12px;
                //     }
                // }

                // .ap-volume-2 {
                //     .ap-volume-icon {
                //         width: 16px;
                //         border-radius: 10px;
                //     }
                // }

                .ap-volume:hover {

                    .volume-slider,
                    .volume-slider-bg,
                    .volume-handle {
                        opacity: 1;
                    }
                }
            }
        }

    }

}

.audio-loaded {
    div.time-slider:hover {
        box-shadow: 0 0 3px 0 rgba(255, 0, 187, 0.6);
        transform: translateZ(0);
        transition: filter .1s;
    }

}

.audio-panel-v {
    transform: translateY(0);
}