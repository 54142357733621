.default-btn-style{
    width: 120px;
    height: 36px;
    border-radius: 10px;
    margin-top: 30px;
    text-align: center;
    line-height: 36px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}
.footer-box{
    display: flex;
    justify-content: center;
    .cancel-btn{
        @extend .default-btn-style;
        background: #f1f1f1;
    }
    .ok-btn{
        @extend .default-btn-style;
        background: linear-gradient(270deg, #FF66BF 11.26%, #6175FF 88.74%);
        color: #fff;
    }
}