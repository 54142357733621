.active-alert-panel-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17, 17, 17, .2);
    backdrop-filter: blur(10px);
    opacity: 0;
    transform: translateZ(0);
    transition: opacity .3s;

    .alert-panel {
        width: 420px;
        position: fixed;
        z-index: 100;
        left: 50%;
        top: 50%;
        border-radius: 6px;
        transform: translate(-50%, -50%) scale(.8);
        background: #fff;
        transition: transform .3s;

        .ap-header {
            height: 120px;
            position: relative;

            .aph-bg {
                width: 372px;
                height: 240px;
                position: absolute;
                left: 29px;
                bottom: 0;
                background-position: center bottom;
                background-repeat: no-repeat;
                background-image: url(../../assets/images/active_code_alert.png);
                background-size: 325px auto;
            }

            .ap-close {
                width: 36px;
                height: 36px;
                position: absolute;
                right: 20px;
                top: 19px;
                background: url(../../assets/images/close_icon.png) no-repeat center;
                background-size: contain;
                transform: rotate(0);
                transition: transform .3s;
                cursor: pointer;
            }

            .ap-close:hover {
                transform: rotate(180deg);
            }
        }

        .ap-content {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #666;

            h6 {
                width: 370px;
                margin: 0;
                padding: 0;
                font-size: 16px;
                text-indent: 12px;
                font-weight: normal;
            }

            ul {

                width: 370px;
                margin: 0;
                padding: 0 0 20px;

                li {
                    height: 58px;
                    margin-top: 12px;
                    border-radius: 6px;
                    border: 1px solid rgba(17, 17, 17, 0.10);
                    background: #F9F9F9;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .ac-code {
                        margin-left: 12px;
                        user-select: all;

                    }

                    .ac-code-user {
                        margin:0 12px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .ac-copy {
                        width: 70px;
                        height: 34px;
                        margin-right: 12px;
                        line-height: 34px;
                        text-align: center;
                        border-radius: 6px;
                        background: #fff;
                        border: 1px solid rgba(17, 17, 17, 0.10);
                        cursor: pointer;
                    }

                }

                .ac-code-used {
                    color: #aaa;

                    .ac-code {
                        user-select: none;
                    }
                }

            }
        }
    }
}

.active-alert-panel-bg-v {
    opacity: 1;

    .alert-panel {
        transform: translate(-50%, -50%) scale(1);
    }
}