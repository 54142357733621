.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  position: absolute;
  z-index: 9;
  width: 100%;
  left: 0;
  bottom: 0;
}
 
.dot {
  margin: 0 3px;
  width: 5px;
  height: 5px;
  background-color: rgb(51 51 51 /30%);
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}
 
.dot:nth-child(2) {
  animation-delay: 0.2s; /* 延迟0.2秒执行动画 */
}
 
.dot:nth-child(3) {
  animation-delay: 0.4s; /* 延迟0.4秒执行动画 */
}
 
@keyframes blink {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}
.loading-input{
  width: 412px;
  height: 48px;
  border-radius: 6px6px;
  border: 1px solid rgba(17, 17, 17, 0.05);
  background: #F9F9F9;
}
.loading-content{
  position: relative;
}