.menu-panel {
    width: 270px;
    position: absolute;
    z-index: 50;
    right: 12px;
    top: 58px;
    opacity:0;
    transform:translateY(10px);
    transition:all .3s;
    .menu-panel-container{
        width: 100%;
        height:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        border-radius: 6px;
        background: #FFF;
    }
    .menu-panel-container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #40A700;
        border-radius: 6px;
        filter: blur(6px);
        z-index: -1;
    }
    ul {
        width: 240px;
        margin: 18px auto;
        padding: 0;
        list-style: none;

       

        li {
            height: 48px;
            line-height: 48px;
            text-indent: 15px;
            font-size: 18px;
            color: #555;
            border-radius: 6px;
            transform: translateZ(0);
            transition: background .2s;
            cursor: pointer;
            position: relative;
            display: flex;

            a,span{
                flex:1
            }
            a:active, a:hover{
                color:#555;
                text-decoration: none;
            }
        }
        li.m-button-qq{
            display:flex;
            justify-content: space-between;
            align-items: center;
        }

        li:hover {
            background-color: #f9f9f9;
        }
  

        .m-create {
            text-indent: 39px;
            background: url(../../assets/images/menu_icon.png) no-repeat 15px center;
            background-size: 8%;
            span {
                display: flex;
                background: #40A700;
                color:#555;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                
            }
        }
        // li.m-create:hover{
        //     background-color:  #fff;
        // }

    }

    .wx-code{
        width:120px;
        height:120px;
        position: absolute;
        left:-145px;
        top:-32px;
        opacity: 0;
        transform: translateX(-10px);
        transition: .2s;
        background:url(../../assets/images/wx_code.jpg) no-repeat center;
        background-size: contain;
        // img{
        //     width:120px;
        //     height:120px
        // }
    }
    .wx-code-v{
        opacity:1;
        transform: translateX(0)
    }
};
.menu-panel-v{
    opacity:1;
    transform: translateY(0);
}