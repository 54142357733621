.footer{
    height:48px;
    box-sizing: border-box;
    padding:14px 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    line-height:20px;
    font-size: 12px;
    color:#ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .fp-left{
        display: flex;
        a{
            width:122px;
            height:24px;
            background:url(../../assets/images/logo_footer.png) no-repeat center;
            background-size: contain;
        }
    }
    .fp-right{
        text-align: right;
        display: flex;
        flex-direction: column;
        span{
            cursor: pointer;
        }
    }
    a:link, a:visited{
        color:#ccc; 
    }
}
