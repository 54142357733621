@font-face {
    font-family: 'dingding';
    src: url('./assets/fonts/DingTalk_JinBuTi.ttf');
}
html {
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    user-select: none;
    max-width: 1150px;
    overflow-x: auto;
    #root {
        height: 100%;
        flex: 1;
    }
}
body,
input,
textarea,
select {
    font-family: 'dingding', system-ui, -apple-system, BlinkMacSystemFont,
        Helvetica Neue, PingFang SC, sans-serif, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #111;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

a:link,
a:active,
a:visited {
    color: #666;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: #ff4b51;
}

img {
    border: 0;
}

.point-icon-white {
    width: 16px;
    height: 16px;
    background: url(./assets/images/point_white.png) no-repeat center;
    background-size: contain;
}
.point-icon-color {
    width: 48px;
    height: 60px;
    background: url(./assets/images/point_color.png) no-repeat center;
    background-size: contain;
}

html {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE/Edge */
}

/* 针对WebKit/Blink内核的浏览器 */
::-webkit-scrollbar {
    // width: 0 !important;
    // background: transparent; /* Chrome, Safari, Opera */
}

/* 针对Firefox（需要额外的CSS变量） */
* {
    // scrollbar-width: thin;
    // scrollbar-color: transparent transparent; /* 第一个值是滚动条的滑块颜色，第二个值是滚动条轨道的颜色 */
}
