@import '../../assets/common.scss';

.user-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-end;
    .member {
        height: 36px;
        margin-right:6px;
        padding: 0 16px;
        border-radius: 6px;
        font-size: 16px;
        color: white;
        display: flex;
        align-items: center;
        background: $line-gradient-pr;

        .points {
            height: 19px;
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                margin-right:2px
            }
        }

        .line {
            width:16px;
            text-align: center;
            margin: 0 3px;
        }
        .upgrade{
            width:66px;
            user-select: none;
            cursor: pointer;
        }
    }

    .login-btn {
        width: 68px;
        height: 36px;
        margin: 0 8px;
        line-height: 36px;
        text-align: center;
        border-radius: 6px;
        border-width: 1px;
        color: #111111;
        border-color: rgba(1, 1, 1, .05);
        background-color: rgba(1, 1, 1, .1);
        user-select: none;
        cursor: pointer;
    }

    .menu {
        display: flex;
        flex-direction: row;
        .active-code{
            width:102px;
            height:34px;
            border-radius: 6px;
            border:1px solid  rgba(17, 17, 17, 0.05);
            background:#FFF url(../../assets/images/active_code_btn.png) no-repeat center;
            background-size: auto 19px;
            cursor: pointer;
        }
        .avatar {
            width: 36px;
            height: 36px;
            margin: 0 6px;
            position: relative;
            img {
                width: 36px;
                height: 36px;
                border-radius: 100%;
            }

            span {
                width: 26px;
                height: 12px;
                position: absolute;
                left: 5px;
                bottom: 0;
            }

            span.vip-1 {
                background: url(../../assets/images/vip.png) no-repeat center;
                background-size: contain;
            }

            span.vip-2 {
                background: url(../../assets/images/svip.png) no-repeat center;
                background-size: contain;
            }
        }
        .avatar-v{
            display: block;
        }

        .menu-btn {
            width:36px;
            height:36px;
            margin-left:6px;
            // background:url(../../assets/images/menu_btn.png) no-repeat center;
            // background-size: contain;
            cursor: pointer;
            position: relative;
            span{
                width:20px;
                height:3px;
                overflow:hidden;
                border-radius: 2px;
                background:#111;
                transform-origin: center;
                transition: transform .2s, all .2s .1s;
                position: absolute;
                left:8px;
                top:12px;
            }
            span:nth-child(2){
                top:20px;
            }
        }
        .menu-btn-open{
            span{
                transition: all .2s, transform .2s .1s;
            }
            span:nth-child(1){
                transform:rotate(-45deg);
                top:15px;
            }
            span:nth-child(2){
                transform:rotate(+45deg);
                top:15px;
            }
        }
    }

}