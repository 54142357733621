.prompt {
    .guide {
        display: flex;
        align-items: center;
        color: #707070;
        font-size: 16px;

        i {
            width: 18px;
            height: 18px;
            background: url(../../../assets/images/music_prompt_icon.png) no-repeat center;
            background-size: contain;
        }

        span {
            margin-left: 6px;
        }
    }

    .prompt-area {
        box-sizing: border-box;
        width: 100%;
        // height: 190px;
        padding: 18px;
        margin-top: 6px;
        position: relative;
        border-radius: 6px;
        border: 1px solid rgba(17, 17, 17, 0.05);
        background: #F9F9F9;
        transform: translateZ(0);
        transition: background .3s linear;

        textarea {
            width: 100%;
            height: 30vh;
            min-height: 100px;
            max-height: 120px;
            box-sizing: border-box;
            padding: 6px;
            resize: none;
            font-size: 16px;
            font-family: "dingding";
            color: #666666;
            border: none;
            background: none;
            outline: none;
            user-select: auto;
        }

        .count-info {
            position: absolute;
            right: 24px;
            bottom: 12px;
            font-size: 12px;
            color: #999;

            i {
                font-style: normal;
            }
        }

        .count-info-light i {
            color: #FF9191;
        }
    }

    .prompt-area-flash {
        background: #efefef;
        border: 1px solid rgba(17, 17, 17, 0.1);
    }

    // .music-title {
    //     margin-top: 12px;

    //     label {
    //         display: block;
    //         color: #666;
    //         font-size: 16px;
    //         text-indent: 24px;
    //     }

    //     div {
    //         height: 8vh;
    //         min-height: 42px;
    //         max-height: 58px;
    //         margin-top: 6px;
    //         color: #666;
    //         display: flex;
    //         justify-content: space-between;
    //         align-items: center;
    //         border-radius: 6px;
    //         border: 1px solid rgba(17, 17, 17, 0.10);
    //         background: #f9f9f9;

    //         input {
    //             margin-left: 24px;
    //             color: #666666;
    //             flex: 1;
    //             border: none;
    //             outline: none;
    //             background: transparent;
    //         }

    //         span {
    //             width: 36px;
    //             text-align: right;
    //             color: #999;
    //             margin-right: 24px;
    //             font-size: 12px;

    //             i {
    //                 font-style: normal;
    //             }


    //         }

    //         .music-title-light i {
    //             color: #FF9191
    //         }


    //     }

    // }

    // .select-prompt {
    //     height: 8vh;
    //     min-height: 42px;
    //     max-height: 58px;
    //     margin-top: 12px;
    //     color: #666;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     border-radius: 6px;
    //     border: 1px solid rgba(17, 17, 17, 0.10);
    //     background: #f9f9f9;
    //     cursor: pointer;

    //     span:nth-child(1) {
    //         margin-left: 24px;

    //     }

    //     span:nth-child(2) {
    //         height: 36px;
    //         margin-right: 12px;
    //         line-height: 36px;
    //         padding-right: 36px;
    //         background: url(../../../assets/images/arrow_right.png) no-repeat right center;
    //         background-size: contain;
    //     }
    // }

    .create-btn {
        div {
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                width: 12px;
                height: 12px;
                background: url(../../../assets/images/create_arrow.png) no-repeat center;
                background-size: contain;
            }
        }

    }

    .err-info {
        height: 0;
        line-height: 36px;
        overflow: hidden;
        color: #FF9191;
        opacity: 0;
        transition: all .3s;
    }

    .err-info-v {
        height: 26px;
        opacity: 1;
    }

    ::placeholder {
        color: #bbb;
    }

    .cr-note{
        margin-top:12px;
        font-size: 12px;
        text-align: center;
        color:#b8b8b8;
    }
}