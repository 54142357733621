.bg-default {
    margin-right: 2px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background-size: contain;
}

.root {
    width: 100vw;
    height: 100vh;
    // overflow: hidden;
    // background: pink;

    // ::-webkit-scrollbar {
    //     display: none;
    // }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {
        flex: 1;
        display: flex;
        overflow-y: auto;

        .left {
            width: 492px;
            height: calc(100% - 20px);
            overflow-y: scroll;
            box-sizing: border-box;
            padding: 100px 40px 40px 40px;
            position: relative;
            flex-shrink: 0;
            .logo {
                display: block;
                width: 165px;
                margin: 0 auto;
                margin-bottom: 60px;
            }

            .pageType-box {
                width: 339px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 12px;
                border: 1px solid #f5f5f5;
                background: #f9f9f9;
                margin: 0 auto;
                margin-bottom: 24px;
                display: flex;
                padding: 6px;
                box-sizing: border-box;
                justify-content: space-between;
                align-items: center;

                .pageType-item {
                    width: 160px;
                    height: 36px;
                    flex-shrink: 0;
                    border-radius: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: 1px solid #f9f9f9;

                    span{
                        font-size: 14px;
                        background: #40A700;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                .pageType-selected {
                    border: 1px solid #f5f5f5;
                    background: #fff;

                    .text-create{
                        background: url(../../assets/images/newPage/text.png) no-repeat
                        center;
                        background-size: 100%;
                    }
                    .photo-create{
                        background: url(../../assets/images/newPage/photo-on.png) no-repeat
                        center;
                        background-size: 100%;
                    }
                }
            }

            .prompt-box {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                .prompt-left {
                    display: flex;
                    align-items: center;
                }

                .prompt-icon {
                    @extend .bg-default;
                    margin-right: 6px;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    background: url(../../assets/images/music_prompt_icon.png)
                        no-repeat center;
                    background-size: contain;
                }

                .custom-icon {
                    background: url(../../assets/images/custom.png) no-repeat
                        center;
                    background-size: 100%;
                }

                .soneTitle-icon {
                    background: url(../../assets/images/newPage/sone-title.png)
                        no-repeat center;
                    background-size: 100%;
                }

                .topic-icon {
                    background: url(../../assets/images/newPage/topic.png)
                        no-repeat center;
                    background-size: 100%;
                }

                .pic-icon {
                    background: url(../../assets/images/newPage/picBg.png)
                    no-repeat center;
                    background-size: 100%;
                }

                .prompt-text {
                    color: #707070;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .textarea-box {
                position: relative;

                .input {
                    width: 100%;
                    height: auto;
                    min-height: 100px;
                    box-sizing: border-box;
                    padding: 12px 18px;
                    resize: none;
                    font-size: 14px;
                    font-family: 'dingding';
                    color: #666666;
                    outline: none;
                    user-select: auto;
                    border-radius: 6px;
                    border: 1px solid rgba(17, 17, 17, 0.05);
                    background: #f9f9f9;
                }

                .count {
                    position: absolute;
                    bottom: 12px;
                    right: 18px;
                    color: #111;
                    text-align: right;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    opacity: 0.3;
                }

                .num {
                    position: absolute;
                    top: 50%;
                    right: 18px;
                    transform: translateY(-50%);
                    color: #111;
                    text-align: right;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    opacity: 0.3;
                }

                .topic-input-icon {
                    position: absolute;
                    top: 50%;
                    left: 18px;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;

                    img{
                        width: 18px;
                        height: 18px;
                    }
                }
            }

            .prompt-random {
                font-size: 12px;
                display: inline-block;
                width: 62px;
                height: 24px;
                border-radius: 12px;
                border: 1px solid #f5f5f5;
                text-align: center;
                line-height: 24px;
                cursor: pointer;
                background: #40A700;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                box-sizing: border-box;
            }

            .random-box{
                position: absolute;
                bottom: 7px;
                left: 18px;
                border: 1px solid #ffffff;
                font-size: 12px;
            }

            .ai-create-box {
                width: 76px;
                position: absolute;
                bottom: 7px;
                left: 18px;
                border: 1px solid #ffffff;
                font-size: 12px;
            }

            .tag-select-box {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                ::-webkit-scrollbar {
                    display: none !important;
                }

                .tag-scroll-box {
                    flex: 1;
                    display: flex;
                    overflow-x: scroll;
                }

                .tag-item {
                    padding: 0 12px;
                    height: 30px;
                    flex-shrink: 0;
                    border-radius: 15px;
                    border: 1px solid rgba(17, 17, 17, 0.05);
                    background: #fff;
                    line-height: 30px;
                    margin-right: 2px;
                    cursor: pointer;

                    .tag-label{
                        font-size: 12px;
                        color: #707070;
                    }
                }

                .change-batch {
                    width: 70px;
                    height: 100%;
                    text-align: right;
                    background: #ffffff;
                    cursor: pointer;
                }
            }

            .textinput-box {
                position: relative;
                width: 100%;

                .textinput {
                    width: 100%;
                    min-height: 100px;
                    box-sizing: border-box;
                    padding: 24px;
                    resize: none;
                    font-size: 16px;
                    font-family: 'dingding';
                    color: #666666;
                    outline: none;
                    user-select: auto;
                    border-radius: 6px;
                    border: 1px solid rgba(17, 17, 17, 0.05);
                    background: #f9f9f9;
                }
            }

            .create-btn-bg-box {
                position: relative;

                .create-btn-bg {
                    position: absolute;
                    top: -50%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 120px;
                    height: 48px;
                }
            }

            .m-t-20 {
                margin-top: 20px;
            }

            .switch-selected {
                background: #40A700 !important;
            }

            .ai-create {
                @extend .bg-default;
                background: url(../../assets/images/newPage/ai.png) no-repeat
                    center;
                background-size: 14px 14px;
            }

            .text-create {
                @extend .bg-default;
                background: url(../../assets/images/newPage/text-off.png) no-repeat
                    center;
                background-size: 100%;
            }

            .photo-create {
                @extend .bg-default;
                background: url(../../assets/images/newPage/photo.png) no-repeat
                    center;
                background-size: 100%;
            }

            .random-icon {
                @extend .bg-default;
                background: url(../../assets/images/newPage/random.png)
                    no-repeat center;
                background-size: 14px 14px;
            }

            .random-style-icon {
                @extend .bg-default;
                background: url(../../assets/images/newPage/get-style.png)
                    no-repeat center;
                background-size: 14px 14px;
            }

            .tips {
                color: #111;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.3;
                margin-top: 12px;
                cursor: pointer;
            }

            .select-img-box {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                cursor: pointer;

                .select-img {
                    display: block;
                    width: 200px;
                    height: 200px;
                    border-radius: 12px;
                    cursor: pointer;
                }
            }

            .upload-box {
                width: 100%;
                height: 296px;
                border-radius: 12px;
                border: 1px solid rgba(17, 17, 17, 0.05);
                background: #f9f9f9;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                cursor: pointer;

                .errMsg-box {
                    width: 100%;
                    height: 36px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background: #dc0d00;
                    opacity: 0.3;
                    line-height: 36px;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
                }

                .upload-btn-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    img{
                        width: 48px;
                        height: 48px;
                    }
                }

                .upload-tips {
                    margin-top: 10px;
                    color: #A0A0A0;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    cursor: pointer;
                }

                .again {
                    display: block;
                    width: 96px;
                    height: 36px;
                    flex-shrink: 0;
                    border-radius: 18px;
                    border: 1px solid rgba(17, 17, 17, 0.05);
                    background: #fff;
                    text-align: center;
                    line-height: 36px;
                    margin-top: 10px;
                    cursor: pointer;
                    color: #111;
                    opacity: 0.6;
                    font-size: 14px;
                    font-weight: 400;
                }

                .img-upload-percent {
                    width: 120px;
                    height: 120px;
                    color: #fff;
                    font-size: 18px;
                    border-radius: 50%;
                    position: absolute;
                    z-index: 10;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    opacity: 0;
                    transform: translateZ(0);
                    transition: opacity 0.3s;
                    background: rgba(0, 0, 0, 0.5);
                    cursor: pointer;
                }

                .img-upload-percent-v {
                    pointer-events: all;
                    opacity: 1;
                    cursor: pointer;
                }
            }

            input[type='file'] {
                position: absolute;
                left: 0px;
                top: 0px;
                font-size: 120px;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0;
                appearance: none;
                cursor: pointer !important;
                background: #333;
            }

            .photo-create-box {
                height: 126px;
                border-radius: 12px;
                overflow: hidden;
                position: relative;
                margin-bottom: 25px;

                .filter-img {
                    position: absolute;
                    top: -150px;
                    left: -15%;
                    display: block;
                    width: 130%;
                    height: 400%;
                    z-index: 1;
                    filter: blur(15px);
                }

                .photo-create-img {
                    position: absolute;
                    display: block;
                    width: 102px;
                    height: 102px;
                    z-index: 5;
                    border-radius: 12px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &::after{
                    content: '';
                    width: 100%;
                    height: 126px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }

        .right {
            flex: 1;
            background: #ffffff;
            height: 100%;
            position: relative;
            z-index: 9;
            min-width: 600px;
            overflow: hidden;
        }
    }

    .bottom {
        height: 78px;
        background: #ffffff;
    }

    .topic-box {
        width: 388px;
        max-height: 200px;
        overflow-y: scroll;

        input[type='file'] {
            position: absolute;
            left: 0px;
            top: 0px;
            font-size: 120px;
            line-height: 120px;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            appearance: none;
        }

        .right {
            flex: 1;
            background: #ffffff;
            height: 100%;
            position: relative;
            z-index: 9;
        }
    }

    .topic-item {
        margin-bottom: 5px;
        background: #ffffff;
        border-radius: 6px;
        height: 36px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        line-height: 36px;
        box-sizing: border-box;
        padding: 0 12px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.8);
    }

    .topic-item:hover {
        background: #f5f5f5;
    }

    .linear-txt-color {
        background: #40A700;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.custom-lyric{
    width: 412px;
    height: 35px;
    position: absolute;
    bottom: 0px;
    background: rgb(249, 249, 249);
    border: 1px solid rgba(17, 17, 17, 0.05);
    border-top: none;
    box-sizing: border-box;
    border-radius: 6px;
}

.create-btn.create-btn-loading{
    position: relative;

    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 48px;
        background: rgba(255, 255, 255, 0.5);
        cursor: wait;
    }
}
.queue-full-modal{
    height: 500px;
}
.queue-full-modal-content{
    position: relative;
    .queue-bg{
        background: url(../../assets/images/alert_bg.png) no-repeat center;
        width: 280px;
        height: 170px;
        background-size: cover;
        position: absolute;
        top: -70px;
        left: 45px;
    }
    .font-content{
        align-content: space-around;
    }
    .queue-full{
        margin-top: 144px;
        font-size: 18px;
        font-family: 'dingding';
        color: #111;
        text-align: center;
        display: block;
        font-weight: 400;
    }
    .queue-full-font{
        font-size: 14px;
        font-family: 'dingding';
        color: #111;
        text-align: center;
        display: block;
        height: 34px;
        opacity: 0.3;
        margin-top: 12px;
        margin-bottom: 24px;
    }
}
.queue-full-modal-footer{
    width: 200px;
    height: 36px;
    line-height: 36px;
    border-radius: 9px;
    background: #40A700;
    color: #FFF;
    text-align: center;
    font-family: "dingding";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.submit-full-modal{
    .queue-bg-submit{
        background-image: url(../../assets/images/submit-bg.png)!important;
        background-size: 233px 163px!important;
    }
    .queue-bg{
        top: -59px!important;
    }
    .queue-full-modal-content .queue-full{
        margin-top: 120px;
    }
}

.prompt-right{
    width: 116px;
    height: 30px;
    border-radius: 9px;
    border: 1px solid #F5F5F5;
    background: #FFF;
    display: flex;
    justify-content: space-around;
    align-items: center;

    span{
        display: inline-block;
        cursor: pointer;
        font-weight: 300;
        font-size: 14px;
        width: 55px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #707070;
    }
    .active{
        width: 55px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background: #40A700;
        color: #FFF;
        font-family: "dingding";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        border-radius: 9px;
    }
}
.no-click{
    pointer-events: none!important;
}